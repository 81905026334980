import { BrowserRouter, Route, Routes } from "react-router-dom";
import RedocPage from "./RedocPage/RedocPage";
import SelectApiPage from "./SelectApiPage/SelectApiPage";

import React, { useState } from "react";
import "./index.css";
import { Auth, Storage } from "aws-amplify";
import Loader from "./Loader/Loader";

function formatTitle(specFilename) {
  let filename = specFilename.replace(/\.[^/.]+$/, "");
  const replacements = [
    [/Api-/, " ["],
    [/Admin-/, " [admin] ["],
    [/-prod/, " [prod"],
    [/-stage/, " [stage"],
    [/-dev/, " [dev"],
  ];

  for (let [pattern, repl] of replacements) {
    filename = filename.replace(pattern, repl);
  }

  return `${filename}]`;
}

const DEFINITIONS_DIR = "docs/";

export const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [projects, setProjects] = useState([]);

  const selectOptions = projects
    .filter((path) => !path.endsWith("/")) // skip directories
    .map((path) => [path, path.substring(DEFINITIONS_DIR.length)])
    .map(([path, filename]) => ({
      value: filename,
      path: path,
      label: formatTitle(filename),
    }));

  const [userGroups, setUserGroups] = React.useState(new Set());

  if (!isAuthenticated) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setIsAuthenticated(true);
        setUserGroups(
          new Set(user.signInUserSession.accessToken.payload["cognito:groups"])
        );
      })
      .catch((e) => {
        console.error(e);
        Auth.federatedSignIn();
      });
  }

  if (projects.length === 0) {
    Storage.list(DEFINITIONS_DIR, {
      pageSize: "ALL",
    })
      .then((result) => {
        const projects = result.results.map((item) => item.key);
        setProjects(projects);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {isAuthenticated ? (
        <BrowserRouter>
          <div>
            <Routes>
              <Route
                exact
                path="/"
                element={<SelectApiPage selectOptions={selectOptions} />}
              />
              <Route
                path="/*"
                element={<RedocPage selectOptions={selectOptions} />}
              />
            </Routes>
          </div>
        </BrowserRouter>
      ) : (
        <Loader />
      )}
    </>
  );
};

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import config from "./config";
import { Amplify } from "aws-amplify";
import { App } from "./app";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    identityPoolId: config.COGNITO_IDENTITY_POOL_ID,
    region: config.REGION,
    userPoolId: config.COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: config.COGNITO_DOMAIN,
      scope: ["email", "openid"],
      redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
  },
  Storage: {
    AWSS3: {
      bucket: config.S3_BUCKET_NAME,
      region: config.S3_BUCKET_REGION,
      customPrefix: { public: "", protected: "", private: "" },
    },
  },
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

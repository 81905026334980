import "./SelectApiPage.css";
import Logo from "../logo.svg";
import SelectApi from "../SelectApi/SelectApi";
import { useNavigate } from "react-router-dom";

function SelectApiPage({ selectOptions }) {
  let navigate = useNavigate();

  return (
    <div>
      <div className="search-wrapper">
        <div>
          <img src={Logo} alt="Redoc" width={"400px"} />
          <SelectApi
            autoFocus="true"
            selectOptions={selectOptions}
            onChange={(selectedApi) => navigate(selectedApi.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectApiPage;

import React from "react";
import Select from "react-select";

function SelectApi({ autoFocus, value, onChange, selectOptions }) {
  return (
    <Select
      className="select__api"
      autoFocus={autoFocus}
      value={value}
      isLoading={selectOptions.length === 0}
      onChange={onChange}
      options={selectOptions}
      noOptionsMessage={() => "No api found"}
      placeholder={"Select API..."}
    />
  );
}

export default SelectApi;

import React, { useState } from "react";
import "./RedocPage.css";
import Logo from "../logo.svg";
import { RedocStandalone, SideNavStyleEnum } from "redoc";
import { Link, useMatch, useNavigate } from "react-router-dom";
import SelectApi from "../SelectApi/SelectApi";
import LogOutButton from "../LogOutButton/LogOutButton";
import { Storage } from "aws-amplify";
import Loader from "../Loader/Loader";

function RedocPage({ selectOptions }) {
  let navigate = useNavigate();
  let [specUrl, setSpecUrl] = useState(null);
  let match = useMatch(":api");
  const [activeApi, setActiveApi] = useState(null);

  if (selectOptions.length === 0) {
    return <Loader />;
  }

  const activeApiFromQuery = selectOptions.find(
    (element) => element.value === match.params.api
  );

  if (!activeApiFromQuery) {
    navigate("/");
  }

  if (!activeApi) {
    setActiveApi(activeApiFromQuery);
  }

  if (!specUrl && activeApi) {
    Storage.get(activeApi.path, { download: false }).then((signedUrl) => {
      setSpecUrl(signedUrl);
    });
  }

  return (
    <div>
      <header className="RedocPage-header">
        <Link to={"/"}>
          <img src={Logo} alt="Redoc" />
        </Link>

        <SelectApi
          className="select"
          selectOptions={selectOptions}
          value={activeApiFromQuery}
          onChange={(selectedApi) => {
            // Change current API spec
            setActiveApi(selectedApi);
            // Remove existing API spec URL to regenerate a new one
            setSpecUrl(null);
            navigate(selectedApi.value);
          }}
        />
        <LogOutButton>Log out</LogOutButton>
      </header>
      <section className="container__redoc">
        <RedocStandalone
          specUrl={specUrl}
          options={{
            nativeScrollbars: true,
            sortEnumValuesAlphabetically: true,
            sortOperationsAlphabetically: true,
            sortTagsAlphabetically: true,
            sortPropsAlphabetically: true,
            requiredPropsFirst: true,
            pathInMiddlePanel: true,
            expandSingleSchemaField: true,
            showObjectSchemaExamples: true,
            showSecuritySchemeType: true,
            expandResponses: "200,201",
            sideNavStyle: SideNavStyleEnum.PathOnly,
            scrollYOffset: 60,
            minCharacterLengthToInitSearch: 1,
            schemaExpansionLevel: 2,
          }}
        />
      </section>
    </div>
  );
}

export default RedocPage;

import { useState } from "react";
import { Auth } from "aws-amplify";
import "./LogOutButton.css";

export const toTitleCase = (str) =>
  str
    .replace("_", " ")
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
function LogOutButton({ children }) {
  const [email, setEmail] = useState("");

  Auth.currentSession().then((res) => {
    setEmail(res.getIdToken().decodePayload().email);
  });

  let [firstName, lastName] = email.split("@")[0].split(".");
  firstName = toTitleCase(firstName || "");
  lastName = toTitleCase(lastName || "");

  const fullName = `${firstName} ${lastName}`;

  return (
    <div>
      <span>{fullName}</span>
      <button className="logout" onClick={() => Auth.signOut()}>
        {children}
      </button>
    </div>
  );
}

export default LogOutButton;

import "./Loader.css";
import { ScaleLoader } from "react-spinners";

function Loader() {
  return (
    <div className="container">
      <ScaleLoader
        // color={color}
        // loading={loading}
        // cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default Loader;
